@media (min-width: 990px) {
  #ProjectsSection .titleContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  #ProjectsSection {
    margin-top: 160px;
  }
}
