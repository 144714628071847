.Footer {
  color: var(--light-text);
  background-color: var(--dark-background);
  padding-top: 80px;
  padding-bottom: 50px;
}

.Footer a {
  color: var(--light-text);
  text-decoration: none;
  width: fit-content;
}

#linksSection {
  display: flex;
  max-width: 500px;
}

#linksSection p {
  margin-right: 60px;
  flex: 1;
}

.Footer h1 {
  font-size: 2.2rem;
  line-height: 2.2rem;
  font-weight: 800;
}

.Footer .gradientTitleTwo {
  font-size: 2rem;
  font-weight: 800;
  margin-top: 4px;
}

.Footer .nameTitle {
  font-size: 2.4rem;
}

.emailAndPhoneComp {
  font-size: 1.2rem;
  line-height: 1.4rem;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
}

.emailAndPhoneComp svg {
  position: absolute;
  left: 0;
  color: rgb(62, 62, 62);
}

.Footer h6 {
  color: rgb(98, 98, 98);
  font-size: 0.9rem;
  font-weight: 400;
  margin-top: 24px;
}

#contactInfo {
  margin-top: 100px;
}

#linksSection {
  margin-top: 90px;
}

@media (min-width: 440px) {
  .Footer h1 {
    font-size: 2.7rem;
    line-height: 2.7rem;
  }

  .Footer .gradientTitleTwo {
    font-size: 2.6rem;
    margin-top: 4px;
  }

  .Footer .nameTitle {
    font-size: 2.8rem;
  }

  .Footer h6 {
    font-size: 1rem;
    margin-top: 30px;
  }

  .emailAndPhoneComp {
    margin-top: 12px;
  }

  #linksSection p {
    font-size: 1.1rem;
  }
}

@media (min-width: 990px) {
  .Footer h1 {
    font-size: 3.2rem;
    line-height: 3.2rem;
  }

  .Footer .gradientTitleTwo {
    font-size: 3.1rem;
    margin-top: 4px;
  }

  .contactAndLinks {
    display: flex;
  }

  .contactAndLinks #contactInfo {
    margin-right: 100px;
    margin-bottom: 20px;
  }

  .contactAndLinks #linksSection {
    margin-top: 100px;
    max-width: 700px;
  }

  .contactAndLinks #linksSection p {
    font-size: 1.2rem;
    margin-right: 100px;
  }
}
