.Contact .emailForm .PrimaryButton {
  margin-top: 50px;
}

.Contact .information h1 {
  font-size: 2.1rem;
  font-weight: 800;
}

.Contact .information p {
  margin: 36px 0px;
  font-size: 1.2rem;
  text-align: justify;
}

.Contact .information .emailAndPhoneComp {
  margin: 30px 0px;
}

.Contact .information a {
  color: var(--dark-900);
}

.Contact .emailForm .loadingContainer {
  width: fit-content;
  margin: 20px auto;
}

.Contact .emailForm .messageSent {
  margin: 80px 0px;
  font-size: 2.2rem;
  font-weight: 800;
}

.Contact .information,
.Contact .emailForm {
  flex: 1;
}

@media (min-width: 780px) {
  .Contact .contactContainer {
    display: flex;
    justify-content: space-between;
  }

  .Contact .contactContainer .information {
    margin-right: 40px;
  }

  .Contact .emailForm .messageSent {
    margin: 0px;
  }
}

@media (min-width: 990px) {
  .Contact .contactContainer .information {
    margin-right: 80px;
  }
}
