#ProjectCarousel {
  margin: 40px 0px;
  position: relative;
}

.arrowsCarousel {
  width: fit-content;
  margin-left: auto;
}

.arrowsCarousel svg {
  margin: 4px 40px 4px 0px;
}

/* Arrows */
#ProjectCarousel .disabledArrow {
  color: rgb(185, 185, 185);
}

/* Carousel Cards
___________________ */
/* Images
__________ */
.carouselCard img {
  width: 100%;
}

.carouselCard div {
  margin: 0px 0px 30px 30px;
}

#ProjectCarousel .SecondaryButton {
  margin-top: 20px;
}

#ProjectCarousel .carouselCard a {
  color: var(--dark-800);
}

#ProjectCarousel .carouselCard {
  max-width: 400px;
  margin-right: 40px;
}

#ProjectCarousel .carouselCard:last-of-type {
  margin-right: 0;
}
