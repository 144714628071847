.Projects h1 {
  font-size: 2.1rem;
  font-weight: 800;
  margin-bottom: 40px;
}

.Projects .cardItem {
  margin-bottom: 100px;
}

.Projects .cardItem a {
  color: var(--dark-800);
}

.Projects .cardItem img {
  width: 100%;
}

.Projects .cardItem article {
  margin: 20px 0px 0;
}

.Projects .cardItem .SecondaryButton button {
  font-size: 1rem;
  font-weight: 400;
}

@media (min-width: 780px) {
  .Projects .cardItem {
    display: flex;
    justify-content: space-between;
  }

  .Projects .imageRightSide {
    flex-direction: row-reverse;
  }

  .Projects .cardItem section {
    width: 48%;
  }
}

@media (min-width: 990px) {
  .Projects .cardItem section {
    width: 46%;
  }

  .Projects .titleFour {
    font-size: 1.2rem;
  }

  .Projects .titleThree {
    font-size: 1.5rem;
  }
}
