#IntroSection {
  max-width: 700px;
}

#IntroSection h1 {
  color: #1a1a1a;
}

#IntroSection p {
  margin: 30px 0px;
  text-align: justify;
}

#IntroSection a {
  text-decoration: underline;
  color: var(--dark-900);
  width: fit-content;
}

#IntroSection .buttonSection {
  display: flex;
  flex-direction: column;
  max-width: 440px;
}

@media (min-width: 450px) {
  #IntroSection .buttonSection {
    flex-direction: row;
    justify-content: space-between;
  }
}

@media (min-width: 600px) {
  #IntroSection p {
    font-size: 1.1rem;
    margin-bottom: 36px;
  }
}
