.innerNav {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.Navbar .SecondaryButton,
.navbarDrawerBox .SecondaryButton,
#ProjectCarousel .SecondaryButton {
  background-color: transparent;
  margin: 0px;
}

.Navbar .SecondaryButton button,
.navbarDrawerBox .SecondaryButton button,
#ProjectCarousel .SecondaryButton button {
  margin: 0px;
  font-size: 1rem;
  font-weight: 500;
}
.Navbar .SecondaryButton {
  margin-left: 10px;
}

.Navbar .SecondaryButton button {
  font-size: 1.1rem;
  font-weight: 600;
}

.Navbar .SecondaryButton svg {
  height: 19px;
  top: 4px;
}

.navbarDrawerBox .SecondaryButton svg {
  height: 16px;
}

.Navbar a,
.navbarDrawerBox a {
  color: var(--dark-900);
  text-decoration: none;
}

.navbarDrawerBox a:hover .SecondaryButton svg,
.Navbar a:hover .SecondaryButton svg {
  right: -8px;
  transition: right 0.18s ease-in;
}

.Navbar a:focus-visible,
.navbarDrawerBox a:focus-visible {
  outline: none;
}

.drawerLinks {
  padding-left: 1px;
}

@media (min-width: 440px) {
  .drawerLinks {
    padding-left: 16px;
  }
}

@media (min-width: 600px) {
  .drawerLinks {
    padding-left: 26px;
    font-size: 1.1rem;
    margin: 4px 0;
  }

  .navbarDrawerBox .SecondaryButton button {
    font-size: 1.1rem;
  }

  .navbarDrawerBox .SecondaryButton svg {
    height: 20px;
    top: 2px;
  }
}
