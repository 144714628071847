#PictureSection {
  height: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 300px;
  max-width: 700px;
  margin: 0 auto;
}

#PictureSection article {
  position: relative;
}

#PictureSection img {
  height: 260px;
}

#PictureSection div {
  margin: 0;
  width: 263.5px;
  height: 260px;
  background-color: black;
  margin-top: -250px;
  margin-left: 12px;
  z-index: -1;
}

@media (min-width: 990px) {
  #PictureSection {
    margin-top: 60px;
    flex: 1;
    justify-content: right;
  }
}
