@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=BhuTuka+Expanded+One&display=swap");

:root {
  --dark-background: #040404;
  --dark-900: #1f1f1f;
  --dark-800: #303030;
  --gray-700: #5a5a5a;
  --gray-500: #848484;
  --gren: #40e2af;
  --blue: #00afd5;
  --pink: #f17fed;
  --pink-selection: #f17fed58;
  --white: #fafafa;
  --dark-text: var(--dark-900);
  --light-text: var(--white);
}

* {
  font-family: "Lato", sans-serif;
  margin: 0px;
}

::-moz-selection {
  background-color: var(--pink-selection);
}

::selection {
  background-color: var(--pink-selection);
}

html,
body {
  height: 100vh;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

/* Primary Button
___________________ */
.PrimaryButton {
  width: 188px;
  height: 68px;
  position: relative;
  display: inline-block;
}

.PrimaryButton button,
.SecondaryButton button {
  border: none;
  font-size: 1.2rem;
  font-weight: 600;
  background-color: var(--dark-background);
  color: var(--light-text);
  width: 180px;
  height: 60px;
  transition: margin 0.28s ease-out;
}

.PrimaryButton div {
  background-image: linear-gradient(
    to right,
    #40e2af,
    #00d7bc,
    #00ccc5,
    #00c0ca,
    #00b3ca,
    #00afd5,
    #00aade,
    #2ca4e4,
    #59a1f6,
    #8d9bff,
    #c18ffd,
    #f17fed
  );
  width: 180px;
  height: 60px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.PrimaryButton button:hover {
  cursor: pointer;
  margin: 8px 0px 0px 8px;
  transition: margin 0.18s ease-in;
}

@keyframes primaryButtonAnimation {
  from {
    margin: 0px;
  }

  to {
    margin: 8px 0px 0px 8px;
  }
}

.PrimaryButton button:focus-visible {
  outline: none;
  animation-duration: 0.95s;
  animation-name: primaryButtonAnimation;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

/* Secondary Button
_____________________ */
.SecondaryButton {
  background-color: transparent;
  width: fit-content;
  display: inline-block;
  margin: 24px 0px;
  position: relative;
}

.SecondaryButton button {
  background-color: transparent;
  padding-left: 0px;
  padding-right: 28px;
  color: var(--dark-900);
  width: fit-content;
  height: fit-content;
  z-index: 2;
}

.SecondaryButton svg {
  height: 20px;
  position: absolute;
  right: 0;
  top: 2px;
  padding-top: 1px;
  transition: right 0.28s ease-out;
}

.SecondaryButton button:hover {
  cursor: pointer;
}

.SecondaryButton button:hover ~ svg {
  right: -8px;
  transition: right 0.18s ease-in;
}

@keyframes secondaryButtonAnimation {
  from {
    right: 0;
  }

  to {
    right: -8px;
  }
}
.SecondaryButton button:focus-visible {
  outline: none;
}

.SecondaryButton button:focus-visible ~ svg {
  animation-duration: 0.65s;
  animation-name: secondaryButtonAnimation;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

/* Gradient Title */
/* Main gradient */
.gradientTitleMain,
.gradientTitleSecondary {
  width: fit-content;
  background-image: linear-gradient(
    to right bottom,
    #40e2af,
    #00d7bc,
    #00ccc5,
    #00c0ca,
    #00b3ca,
    #0eb3ca,
    #18b3cb,
    #1fb3cb,
    #25bfce,
    #34ccd0,
    #47d7cf,
    #5de3ce
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Secondary Gradient */
.gradientTitleSecondary {
  background-image: linear-gradient(
    to right,
    #40e2af,
    #00d7bc,
    #00ccc5,
    #00c0ca,
    #00b3ca,
    #00afd5,
    #00aade,
    #2ca4e4,
    #59a1f6,
    #8d9bff,
    #c18ffd,
    #f17fed
  );
}

/* Title */
/* Title 1 */
.titleOne {
  margin: 0px;
  line-height: 62px;
  font-size: 3.8rem;
}

/* Title 2 */
.titleTwo {
  font-weight: 800;
  font-size: 1.9rem;
}

/* Gradient Title 2 */
.gradientTitleTwo {
  margin: 0px;
  font-size: 1.5rem;
}

/* Title 3 */
.titleThree {
  font-weight: 900;
  font-size: 1.4rem;
  margin: 0px;
  line-height: 22px;
}

/* Title 4 */
.titleFour {
  margin: 18px 0px;
  font-weight: 600;
  letter-spacing: 2px;
}

/* Sections spacing */
/* Primary Top Margin */
.intialTopMargin {
  margin-top: 60px;
}

.primaryTopMargin {
  margin-top: 120px;
}

/* General Containers */
/* General Container */
.generalContainer {
  padding: 0 16px;
  min-width: 300px;
}

/* Max Growth */
.maxGrowth,
.secondaryMaxGrowth,
.tertiaryMaxGrowth {
  margin: 0 auto;
}

.maxGrowth {
  width: 100%;
  max-width: 1040px;
}

/* Secondary Max Growth */
.secondaryMaxGrowth {
  max-width: 700px;
}

/* Tertiary Max Growth */
.tertiaryMaxGrowth {
  max-width: 500px;
}

/* Dot animation */
.dotPulse {
  position: relative;
  left: -9999px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #40e2af;
  color: #40e2af;
  box-shadow: 9999px 0 0 -5px #40e2af;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dotPulse::before,
.dotPulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #40e2af;
  color: #40e2af;
}

.dotPulse::before {
  box-shadow: 9984px 0 0 -5px #40e2af;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dotPulse::after {
  box-shadow: 10014px 0 0 -5px #40e2af;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9978px 0 0 -5px #40e2af;
  }
  30% {
    box-shadow: 9978px 0 0 -1px #40e2af;
  }
  60%,
  100% {
    box-shadow: 9978px 0 0 -5px #40e2af;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px #40e2af;
  }
  30% {
    box-shadow: 9999px 0 0 -1px #40e2af;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #40e2af;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10020px 0 0 -5px #40e2af;
  }
  30% {
    box-shadow: 10020px 0 0 -1px #40e2af;
  }
  60%,
  100% {
    box-shadow: 10020px 0 0 -5px #40e2af;
  }
}

@media (min-width: 440px) {
  .generalContainer {
    padding: 0 32px;
  }
}

@media (min-width: 600px) {
  .generalContainer {
    padding: 0 42px;
  }
}

@media (min-width: 780px) {
  .tertiaryMaxGrowth {
    max-width: 100%;
  }
}

@media (min-width: 990px) {
  .generalContainer {
    padding: 0 110px;
  }

  .secondaryMaxGrowth {
    max-width: 100%;
  }
}
