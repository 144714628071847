.ProjectDetails img {
  width: 100%;
}

.ProjectDetails h1 {
  margin-bottom: 18px;
}

.ProjectDetails p {
  margin: 20px 0;
  text-align: justify;
  font-weight: 300;
  color: black;
}

.ProjectDetails a {
  color: black;
}

.ProjectDetails h3 {
  margin: 10px 0px;
}

.ProjectDetails article {
  margin: 20px 0 30px;
}

@media (min-width: 780px) {
  .ProjectDetails .projectSection {
    display: flex;
    justify-content: space-between;
  }

  .ProjectDetails .projectSection section {
    width: 48%;
  }
}

@media (min-width: 990px) {
  .ProjectDetails .projectSection section {
    width: 46%;
  }

  .ProjectDetails .titleFour {
    font-size: 1.2rem;
  }

  .ProjectDetails .titleThree {
    font-size: 1.5rem;
  }
}
